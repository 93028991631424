export const environment = {
  cloudApiUri: 'https://digitaltwin-api-validation.stack-suite.net/',
  redirectUri: 'https://digitaltwin-frontend-validation.stack-suite.net',
  identity: {
    clientId: '58d89359-25ec-4a45-a539-f20fcd8a0818',
    scopes:
      'openid profile offline_access 58d89359-25ec-4a45-a539-f20fcd8a0818',
    authority:
      'https://login-uat.kion.cloud/2e848591-58a9-4cb1-8893-9b2a5b66e0b6/B2C_1A_CXP_SigninSignup_KION/v2.0',
    authWellknownEndpointUrl:
      'https://login-uat.kion.cloud/2e848591-58a9-4cb1-8893-9b2a5b66e0b6/B2C_1A_CXP_SigninSignup_KION/v2.0/.well-known/openid-configuration',
    redirectUrl: 'https://digitaltwin-frontend-validation.stack-suite.net',
    securedEndpoints: ['https://digitaltwin-api-validation.stack-suite.net/']
  }
};
